<template>
  <div>
    <EventTop />
  </div>
</template>
<script>
import EventTop from "@/components/Event/EventTop.vue";
// import NavTop from '@/components/NavTop.vue';
export default {
  components: {
    EventTop,
  },
};
</script>
