<template>
  <v-app>
    <div id="app">
      <router-view />
    </div>
  </v-app>
</template>
<script>
export default {
  name: 'App',
  
};
</script>
